/* eslint-disable jsx-a11y/anchor-is-valid */
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import React from 'react'
import { BackgroundImg, Img } from 'react-cloudimage-responsive'
import Section from './components/Section'
import './css/App.scss'
import logo from './img/Logo-Idinvest-HD-Transparent.png'
import logo_parents_zens_rond from './img/logo_parents_zens_rond.png'
import mpec_logo_new_2 from './img/mpec_logo_new_2.png'

function App () {

  const getContentBlueBand = () => {
    return (
      <div className="d-flex justify-content-center w-100">
        <div className="col-md-8 col-md-offset-2 text-center single-line">
          <a href={process.env.REACT_APP_URL_UJCALC} target="_blank" className="btn btn-ghost btn-md">Trouver des activités pour mes enfants<i
            className="fa fa-arrow-right ml-3"></i></a>
        </div>
      </div>
    )
  }

  const getSection1 = () => {
    return (

      <div className="max-h d-flex w-100 justify-content-between flex-column flex-md-row">
        <div className="col-sm-12 col-md-6 block-txt-simple text-center shadow max-w">
          <img src={mpec_logo_new_2} alt="Logo maplaceencreche" className="logo-size"/>
          <p>Votre employeur met à votre disposition de nouveaux services innovants dédiés à la parentalité, du début de la grossesse au statut de grand-parent. Retrouvez ici
            l'ensemble des informations liées à ces nouveaux services et inscrivez-vous !
          </p>
        </div>
        <div className="col-sm-12 col-md-6 mt-5 mt-md-0 max-w imgRadius no-padMobile">
          <Img
            src={'enfant-livre.jpg'}
            ratio={1}
            params={
              {
                gravity: 'auto'
              }
            }
            sizes={
              {
                '(max-width: 700px)': { h: '10vh' },
                '(min-width: 701px)': { w: '450px', h: '350px' }
              }
            }
          />
        </div>
      </div>
    )
  }

  const getSection2 = () => {
    return (
      <div className="container">
        <div className="no-gutters shadow">
          <div className="col-12">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link title" type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne" aria-expanded="true"
                            aria-controls="collapseOne">
                      Place en crèche
                    </button>
                  </h5>
                </div>
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Votre service Ressources Humaines met à votre disposition des places en crèche. Pour vous inscrire, suivez le guide :</p>
                    <p>
                      <a
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        href={'https://maplaceencreche-my.sharepoint.com/personal/myriam_smadja_lefort_maplaceencreche_com/Documents/Fichiers%20de%20conversation%20Microsoft%20Teams/Ele%CC%81ments%20communication%202021%20-IDINVEST%20-%20maplaceencre%CC%80che.pdf'}>S'inscrire
                        pour obtenir une place en crèche</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFive">
                  <h5 className="mb-0">
                    <button className="btn btn-link title collapsed" type="button"
                            data-toggle="collapse"
                            data-target="#collapseFive" aria-expanded="false"
                            aria-controls="collapseFive">
                      Garde Complémentaire
                    </button>
                  </h5>
                </div>
                <div id="collapseFive" className="collapse" aria-labelledby="headingFive"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p><strong>Besoin d'une solution de garde pour vos enfants ?</strong></p>
                    <p>
                      Sortie d'école, sortie de crèche, enfants malades, vacances scolaires, nous mettons à votre disposition un service de complément de garde pour vous permettre
                      de mieux concilier vie perso et vie pro.
                    </p>
                    <p>
                      Grâce à votre employeur IDINVEST PARTNERS, vous bénéficiez ainsi d'un accès privilégié à la plateforme O2, le leader du service à domicile depuis 20 ans.
                    </p>
                    <p><strong>Les avantages : </strong></p>
                    <ul>
                      <li>4h de prestation offertes</li>
                      <li>Aides de la CAF</li>
                      <li>Possibilité d'un crédit d'impôts de 50%</li>
                      <li>Aucune gestion administrative</li>
                      <li>Des gardes d'enfants formées aux gestes de premiers secours à l'enfant</li>
                      <li>Une méthode ludo éducative approuvée</li>
                      <li>Un devis personnalisé</li>
                      <li>Une visite gratuite d'évaluation du besoin à domicile</li>
                    </ul>
                    <p><strong>Comment faire pour en bénéficier ?</strong></p>
                    <p>Rendez-vous sur la page dédiée de notre partenaire en cliquant sur en savoir plus :
                      <a href='https://www.o2.fr/lp/partenaires/mpec' target="_blank" rel={'noopener noreferrer'}>
                        Bénéficier du service
                      </a>
                    </p>
                    <p>
                      Vous pouvez également téléphonez au 02 43 72 02 02 du lundi au vendredi de 7h00 à 20h30 et le samedi de 8h45 à 18h00.
                    </p>
                    <p>
                      Lors de votre échange avec leurs équipes, pensez à renseigner votre code avantage pour bénéficier des services : "MaPlaceEnCreche"
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingSix">
                  <h5 className="mb-0">
                    <button className="btn btn-link title collapsed" type="button"
                            data-toggle="collapse"
                            data-target="#collapseSix" aria-expanded="false"
                            aria-controls="collapseSix">
                      Parents zens
                    </button>
                  </h5>
                </div>
                <div id="collapseSix" className="collapse" aria-labelledby="headingSix"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Besoin d'un coup de pouce pour gérer une situation familiale ponctuelle ?</p>
                    <p>Nous mettons à votre disposition un service de conseil et d'information individualisé
                      sur des sujets très variés.</p>
                    <ul>
                      <li>Être parent : réagir face aux remarques de son entourage, gérer sa culpabilité...</li>
                      <li>Les étapes de la vie : alimentation du bébé, sommeil, propreté, devoirs...</li>
                      <li>Les défis comportementaux: violence, manque de respect, opposition, organisation des tâches ménagères...</li>
                      <li>La gestion des relations : la jalousie au sein de la fratrie...</li>
                    </ul>
                    <p>Des experts se tiennent à votre disposition pour échanger sur vos questions familiales, parentales à
                      tout moment. Ils vous aident à aborder la situation sous un nouvel angle et à amorcer une réflexion
                      pour trouver la réponse adaptée à votre besoin.</p>
                    <p><strong>Comment ?</strong></p>
                    <ul>
                      <li>Chaque session dure 30 minutes</li>
                      <li>Vous choisissez un créneau qui vous convient</li>
                      <li>Vous choisissez le mode de communication (visio ou téléphone)</li>
                      <li>Votre demande reste confidentielle</li>
                    </ul>
                    <p><strong>Important : le nombre de session est limité</strong></p>
                    <a
                      href='https://forms.office.com/Pages/ResponsePage.aspx?id=rnTFUQTXuUaqt1-nQIoKZZinsJplemZAnChcm9TCw0xUMFFFVFdVUjRXRTBQRDNZWjNJTEk0WkFJQy4u'
                      target="_blank"
                      rel={'noopener noreferrer'}
                    >
                      Bénéficier du service
                    </a>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingSeven">
                  <h5 className="mb-0">
                    <button className="btn btn-link title collapsed" type="button"
                            data-toggle="collapse"
                            data-target="#collapseSeven" aria-expanded="false"
                            aria-controls="collapseSeven">
                      Retour Parents zens
                    </button>
                  </h5>
                </div>
                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven"
                     data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Pourquoi : Pour gérer dans les meilleures conditions l’arrivée prochaine de votre futur enfant et ses premières
                      semaines de vie au sein de votre foyer.</p>
                    <p>Education positive, vie de couple, vie de famille, gestes du quotidien... Chaque nouveau parent bénéficie
                      d’un atelier personnel pour son couple pendant la grossesse puis d’un suivi pendant 3 mois.</p>
                    <p>Pour qui : Suivi personnel de chaque couple</p>
                    <p>Format : Visio-conférence</p>
                    <p>Intervenant : L’atelier du futur parent</p>
                    <p><strong>Important : le nombre de session est limité</strong></p>
                    <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=rnTFUQTXuUaqt1-nQIoKZZinsJplemZAnChcm9TCw0xUQTNYU1FIR1haTUREVVM2QjJOMzFNRTQ0My4u'
                       target="_blank"
                       rel={'noopener noreferrer'}
                    >
                      Bénéficier du service
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const getSection3 = () => {
    return (
      <div className="container">
        <div className="no-gutters question-mail shadow block-txt-simple2">
          <div className="col-12">
            <div className="sizetext d-flex justify-content-center p-3 margin--p plus">
              <p><span className="color-mpec">Vous avez des questions ? Contactez votre experte famille Cécile Fleutiaux : </span><br/>
                <a href="mailto:cecile.fleutiaux@maplaceencreche.com" target="_blank"
                   className="d-flex flex-row underline justify-content-center">cecile.fleutiaux@maplaceencreche.com
                </a>
                06 68 08 88 52</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="m-auto ioej">
        <img src={logo} alt="logo-idinvestpartners" className="logo"/>
      </div>
      <div className={'container-fluid'}>
        <div className={'row'}>
          <div className={'col-12 no-pad'}>
            <BackgroundImg
              src="petite_fille_robe_bleu.jpg"
              style={{ background: '50% 50% / cover no-repeat' }}
            >
              <header className="masthead bg-header w-100">
                <div className="d-flex flex-column justify-content-center">
                  <div className={'d-flex col-12 col-sm-3 justify-content-center mb-sm-0 mb-4'}>
                    <img src={logo_parents_zens_rond} alt="logo-pz" className="logo-pz"/>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <div className="blueround shadow">
                      <p className="text-uppercase nav-head">vos services parentalité</p>
                    </div>
                  </div>
                </div>
              </header>
            </BackgroundImg>
          </div>
          <Section classSup={'bg-blue w-100'} content={getContentBlueBand()}/>
          <div className="container">
            <Section content={getSection1()} classSup={'max-h mb-5'}/>
            <Section classSup={'w-100'} content={getSection2()}/>
            <Section classSup={'w-100'} content={getSection3()}/>
          </div>
          <footer className="block-section w-100">
            <div className="col-12 col-sm-7">
                    <span className="type--fine-print">©
                        <span className="update-year">2021</span> maplaceencrèche
                    </span>
              <a className="type--fine-print" href="https://www.maplaceencreche.com/legale/cgu/" target="_blank"
                 rel="noopener noreferrer"> CGU</a>
              <a className="type--fine-print" href="https://www.maplaceencreche.com/legale/mentions-legales/"
                 target="-_blank" rel="noopener noreferrer"> Mentions légales</a>
              <a className="type--fine-print"
                 href="https://www.maplaceencreche.com/qui-sommes-nous/notre-mission/" target="_blank"
                 rel="noopener noreferrer"> Notre
                mission</a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default App